import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { respondTo } from "../_respondTo"

import { FiArrowDownCircle } from "react-icons/fi"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"

import BackgroundSlider from "gatsby-image-background-slider"

import logo from "../images/watson-laird-logo-white.svg"
import TwmButton from "../components/twm-button"
import Part from "../components/part"
import { palette } from "../_variables"
import { FlexWrapper, FlexItem } from "../components/contentwrappers"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(115, 115, 115, 0.75);
`

const LogoWrapper = styled.div`
  margin: 0 10vw;
  text-align: center;
  padding: auto;
  position: relative;
  top: 0;

  ${respondTo.sm`
    margin: 0 20vw;
`}
`

const StrapLine = styled.div`
  bottom: 17vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  color: white;
  position: absolute;
`

const ScrollButton = styled.a`
  color: white;
  font-size: 30px;
  border-radius: 30px;
  position: absolute;
  bottom: 5%;

  ${respondTo.md`
  font-size: 40px;
  border-radius: 40px;

`}

  :hover {
    background-color: ${palette.primary};
  }

  transition: background-color 1s ease;
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `Watson Laird`,
        `Financial Planning`,
        `Wealth Management`,
        `Financial Advice`,
        `Pensions`,
        `Investments`,
        `Financial Protection`,
      ]}
    />
    <Wrapper>
      <LogoWrapper>
        <img
          src={logo}
          alt="Watson Laird logo"
          style={{ width: "1000px", maxHeight: "50vh" }}
        ></img>
      </LogoWrapper>
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "backgrounds" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        `)}
        initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={4} // transition duration between images
        duration={8} // how long an image is shown
        // specify images to include (and their order) according to `relativePath`
        images={["lighthouse2.jpg", "mountain-climb2.jpg", "path1.jpg"]}
        alt=""
        // pass down standard element props
      >
        {/* Captions in sync with background images*/}

        <StrapLine>Helping you make smart financial choices</StrapLine>
        <StrapLine>Your Goals. Your Path</StrapLine>

        <StrapLine>Building a brighter future together</StrapLine>
      </BackgroundSlider>
      <ScrollButton href="/#wl">
        <FiArrowDownCircle></FiArrowDownCircle>
      </ScrollButton>
    </Wrapper>

    <div style={{ height: "100vh" }}></div>
    <Section id={"wl"} type="wide">
      <FlexWrapper blogs>
        <FlexItem>
          <h1>About You</h1>
          <p>
            Your personal finances, your family's finances, and if you are a
            business owner, your business's financial futures are all interwoven
            together. We will take time to really understand these intricacies,
            to ensure the financial plan matches your requirements, but is also
            flexible and adaptable.
          </p>
          <TwmButton toRef="/about-you">Read more</TwmButton>
        </FlexItem>

        <FlexItem>
          <h1>What We Do</h1>
          <p>
            The best way to achieve 'true wealth' will be different for each
            client. At Watson Laird, we have developed a number of bespoke
            propositions for our clients.
          </p>
          <TwmButton toRef="/what-we-do">Read more</TwmButton>
        </FlexItem>

        <FlexItem>
          {" "}
          <h1>True Wealth Group</h1>
          <p>
            Watson Laird is part of the True Wealth group of companies which
            comprises a number of financial advice practises as well as firms
            that provide services to financial advisers.
          </p>
          <TwmButton toRef="/true-wealth-group">Read more</TwmButton>
        </FlexItem>
      </FlexWrapper>
    </Section>
  </Layout>
)

export default IndexPage
